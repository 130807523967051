import TrackableReact from '../trackableReact';

export default class ErrorToast extends TrackableReact {
  static defaultProps = {
    error: false,
    statusCode: 0
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error || prevProps.statusCode !== this.props.statusCode)
      this.setState({show: this.props.error && this.props.statusCode});
  }

  render() {
    return (
        this.state.show ?
          <div className='toast' style={{opacity: this.props.error && this.props.statusCode ? '1' : '0'}} role='alert'>
            <div className='toast-header'>
            <strong className='mr-auto' style={{backgroundColor: 'rgba(249, 249, 249, 1)'}}>Error</strong>
              <button type='button' className='ml-2 mb-1 close'>
                <span aria-hidden='true' onClick={() => {this.setState({show: false})}}>&times;</span>
              </button>
            </div>
            <div className='toast-body' style={{color: 'red', backgroundColor: 'white'}}>{
              this.props.error && this.props.statusCode == 404 ? 'Your registration link has expired. Please attempt to login with your details.' :
                'An error has occurred. Please attempt to login with your details.'
            }</div>
          </div>
        : ''
    )
  }
}
