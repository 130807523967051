import React from 'react';
import Analytics from './windowAnalytics';
import Location from './windowLocation';

export default class TrackableReact extends React.Component {
    componentDidMount() {
        if (this.state.userId /*&& !Analytics.storedId*/) {
            window.analytics.identify(this.state.userId);
            //Analytics.storedId = this.state.userId;
        }

        if (Location.href !== Analytics.priorHref) {
            window.analytics.page();
            Analytics.priorHref = Location.href;
        }


    }

    track = (event, properties, options, callback) => {
        if (!properties) properties = {};
        if (!properties.source) properties.source = "Connect (Client)";
        window.analytics.track(event, properties, options, callback);
    }

    identify = (id, traits, options) => {
        //if (Analytics) {
        //    Analytics.identify(id, traits, options);
        //} else {
        const includedTraits = ['email', 'firstName', 'lastName', 'name', 'memberDetails', 'userStatus'];
        let newTraits = {};

        includedTraits.forEach(key => {
            if (traits && traits[key]) {
                if (key == "memberDetails" && Array.isArray(traits[key])) {
                    newTraits[key] = [];
                    for (let thisOrg of traits[key]) {
                        newTraits[key].push(thisOrg.company);
                    }                    
                } else {
                    newTraits[key] = traits[key] 
                }
            }
        });
    
        try {
            let resetTraits = false;
            let oldUserId = "";
            try {
                oldUserId = JSON.parse(localStorage.getItem('ajs_user_id'));
            } catch (errUserId) {
                console.error(`[TrackableReact] Could not get old userId`);
                console.error(errUserId);
            }
            if (oldUserId != id) resetTraits = true;
            let oldUserTraits = "";
            try {
                oldUserTraits = JSON.parse(localStorage.getItem(`ajs_user_traits`));
            } catch (errUserTraits) {
                console.error(`[TrackableReact] Could not get old user traits`);
                console.error(errUserTraits);
            }
            if (oldUserTraits?.toString()?.length > 10000) resetTraits = true;
            if (resetTraits) {
                //window.analytics.reset()
                localStorage.setItem(`ajs_user_traits`, []);
            }
        } catch (err) {
            console.error(`[TrackableReact] Could not clear user traits`);
            console.error(err);
        }
        window.analytics.identify(id, newTraits, options);
        //}
    }
}