import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  RecaptchaVerifier,
  multiFactor,
  updatePassword,
} from 'firebase/auth';
import MyStorage from './storage';
import firebaseConfig from './login/firebaseConfig.json';
initializeApp(firebaseConfig);

const auth = getAuth();
const phoneAuthProvider = new PhoneAuthProvider(auth);

export const MFA_PHONE_FACTORID = PhoneMultiFactorGenerator.FACTOR_ID;
export const getUser = () => {
  getAuth().currentUser?.getIdToken(true);
  // console.log(getAuth());
  return getAuth().currentUser;
};

export const getRecaptcha = (recaptchaEl) => {
  return new RecaptchaVerifier(auth, recaptchaEl, {size: "invisible"});
}

export const updateFirebasePassword = async (password) => {
  let user = getUser();
  if (!user) throw {code: 'custom/no-user-found'}
  return await updatePassword(user, password)
    .then(() => true)
    .catch(() => false);
}

export const updateMostRecentFactors = (factors) => {
  const factorObj = {};
  if (!Array.isArray(factors)) return;
  factors.forEach(factor => {
    if (!factor.factorId) return;
    if (!factorObj[factor.factorId]) factorObj[factor.factorId] = factor;
    else {
      const [date1, date2] = [
        new Date(factorObj[factor.factorId].enrollmentTime || 0),
        new Date(factor.enrollmentTime || 0)
      ];
      if(date2.getTime() > date1.getTime()) factor[factor.factorId] = factor;
    }
  });
  MyStorage.set('factors', factorObj);
}

export const signInToFirebase = async (email, password) => {
  const result = await signInWithEmailAndPassword(auth, email, password)
    .then(credential => {
      return {
        type: 'credential',
        data: credential
      }})
    .catch(err => {
      if (!err?.code) console.error(err);
      if (err.code == 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, err);
        updateMostRecentFactors(resolver?.hints || []);
        return {
          type: 'multiFactor',
          data: resolver
        }
      }
      return null;
    });
  return result;
}

export const multiFactorAuth = async (session, factor, recaptchaVerifier) => {
  switch(factor.factorId) {
    case PhoneMultiFactorGenerator.FACTOR_ID:
      const phoneInfoOptions = {
        multiFactorHint: factor,
        session
      }
      return verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    default:
      break;
  }
}

export const verifyPhoneNumber = async (options, recaptchaVerifier) => {
  const verifiedId = await phoneAuthProvider.verifyPhoneNumber(options, recaptchaVerifier);
  return verifiedId;
}

export const getUserSession = async () => {
  const user = getUser();
  if (!user) throw ({code: 'custom/user-not-found'});
  return await multiFactor(user).getSession();
}

export const getFactorType = (factor) => {
  if (!factor) return "unknown";
  switch(factor.factorId) {
    case PhoneMultiFactorGenerator.FACTOR_ID:
      return "phone";
    default:
      return "unknown";
  }
}

export const getPhoneAssertion = (verificationId, code) => {
  const cred = PhoneAuthProvider.credential(verificationId, code);
  return PhoneMultiFactorGenerator.assertion(cred);
}

export const resolveVerification = async (type, verificationId, code, resolver) => {
  let assertion;
  switch(type) {
    case "phone":
      assertion = getPhoneAssertion(verificationId, code);
      break;
    default:
      console.error("Unknown factor type");
      return null;
  }
  return await resolver.resolveSignIn(assertion);
}

export const enrollPhoneNumber = async(verificationId, code) => {
  const assertion = getPhoneAssertion(verificationId, code)
  await multiFactor(getUser()).enroll(assertion, "Default");
  return;
}
