import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
  multiFactorAuth,
  getFactorType,
  resolveVerification,
  MFA_PHONE_FACTORID,
  getRecaptcha
} from './firebaseFns'

export default ({resolver, onEnd}) => {
  const [step, setStep] = useState('options');
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationType, setVerificationType] = useState('unknown');
  const [messageType, setMessageType] = useState({type: 'none', code: ''});
  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const recaptchaRef = useRef();

  useEffect(() => {
    setRecaptchaVerifier(getRecaptcha(recaptchaRef.current));
  }, []);

  const message = useMemo(() => {
    if (messageType.type === 'none') return '';
    switch(messageType.code) {
      case 'invalidCode':
        return 'Your code is incorrect. Please try again.';
      default:
        return 'An unknown error has occured. Please refresh and try again.'
    }
  }, [messageType]);

  const runFactor = async(type, factor) => {
    try {
      const verificationId = await multiFactorAuth(resolver.session, factor, recaptchaVerifier);
      setStep('verifyCode');
      setVerificationId(verificationId);
      setVerificationType(type);
    } catch(err) {
      handleError(err);
    }
  }
  const verifyInputCode = async() => {
    if (verificationCode.length !== 6) {
      setMessageType({type: 'error', code: 'invalidCode'});
      return;
    } else if (verificationType === 'unknown') {
      setMessageType({type: 'error', code: 'unknown'});
      return;
    }
    try {
      const userCredential = await resolveVerification(verificationType, verificationId, verificationCode, resolver);
      console.log(userCredential);

      onEnd(userCredential);
    } catch (err) {
      console.error(err);
      handleError(err);
    }
  }
  const handleError = (err) => {
    if (!err?.code) {
      setMessageType({type: 'error', code: 'unknown'});
      return;
    }
    switch (err.code) {
      case 'auth/invalid-multi-factor-session':
        window.location.reload();
        break;
      case 'auth/invalid-verification-code':
        setMessageType({type: 'error', code: 'invalidCode'});
        break;
      default:
        setMessageType({type: 'error', code: 'unknown'});
    }
  }

  const renderOptions = () => {
    const [phone] = [[]];
    resolver?.hints?.forEach((factor, i) => {
      if (!factor) return;
      switch (getFactorType(factor)) {
        case MFA_PHONE_FACTORID:
          phone.push({type: 'phone', phoneNumber: factor.phoneNumber, factor});
          break;
        default:
          break;
      }
    });
    return (
      <div className='col gap-2'>
        <div className='text-primary'>
          <p>Welcome back! We're glad to see you're taking the security of your account seriously with extra verification.</p>
          <p>To complete your login, please enter the verification code sent to your selected method:</p></div>
        <div className='d-flex flex-column justify-content-center'>
          { phone.map(({type, phoneNumber, factor}, i) =>
            <button
              key={`factor-${i}`}
              className='btn btn-primary mt-2'
              onClick={() => {runFactor(type, factor)}}
            >Text Message (SMS) to {phoneNumber}</button>
          ) }
        </div>
      </div>
    )
  }
  const getPhoneVerificationText = () => {
    return (
      <>
      <p>Great choice! We've sent a verification code to the mobile number associated with your account.</p>
      <p>Please check your phone for the code and enter it below. If you haven't received the code within a few minutes, you can request a new one by logging in again.</p>
      </>
    )
  }
  const renderVerifyCode = () => {
    let title = <p>Type in your verification code:</p>;
    switch(verificationType) { 
      case 'phone':
        title = getPhoneVerificationText();
    }
    return (
      <div>
        <div className='form-floating text-primary'>
          {title}
          { messageType.type !== 'none' && message ?
              <p className={`text-${messageType.type === 'error' ? 'danger' : 'success'}`}>{message}</p> :
              ''
          }
        </div>
        <div className='form-floating'>
          <input
            id='code-input'
            className={`form-control`}
            inputMode='numeric'
            pattern='\d*'
            maxLength='6'
            onChange={(e) => {setVerificationCode(e.target.value)}}
            required
          />
          <label htmlFor='code-input' className='form-label'>Verification Code</label>
        </div>
        <div className='row mt-2'>
          <div className='col text-right'>
            <button
              className='btn btn-primary'
              onClick={verifyInputCode}
            >Verify Code</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      { step === 'options' ?
        renderOptions() :
        renderVerifyCode()
      }
      <div ref={recaptchaRef}></div>
    </>
  )
} 
