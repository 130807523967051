import { prependOnceListener } from "../logger";
import MyStorage from './storage';


declare var pendo;

const protectAttributes = ['password', 'encrypted_password', 'confirm_password'];
const profile = MyStorage.get('profile');

const oldIdentify = window.analytics.identify;
const oldTrack = window.analytics.track;

export default class Analytics {
  identify(id, profileArg, options) {
    if (profileArg) protectAttributes.forEach(protectedAttr => { delete profileArg[protectedAttr]; });
    if (profile && profile.userId) pendo.identify(profile.userId);
    window.analytics.identify(id, profileArg, options);
  };
  
  track(msg, properties, options) {
    if (profile && profile.userId) pendo.track(msg, profile.userId)
    window.analytics.track(msg, properties, options);
  }

  page = () => {
    window.analytics.page();
  }

}
